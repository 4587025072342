import { BrowserCacheLocation } from "@azure/msal-browser";

const azureB2CDomain = 'idbgextt';
const azureB2CTenant = '1f87f52a-35d7-4344-9c79-095847954509';
const azureB2CPolicy = 'B2C_1A_SUSI_IDBG';
const azureAdTenant= '9dfb1a05-5f1d-449a-8960-62abcb479e7d';

export const environment = {
    apiURL: 'https://amazonia-api-dev.azurewebsites.net/api/',
    msalConfig: {
        auth: {
            azureB2CTenant,
            azureAdTenant,
            azureB2CDomain,
            clientId: 'ae7cd21e-907e-4d43-b56f-c089dcaf27c0',
            clientAdId: 'e6976555-9b89-4131-b4e0-87e88534d7d7',
            authority: `https://${azureB2CDomain}.b2clogin.com/${azureB2CDomain}.onmicrosoft.com/${azureB2CPolicy}`,
            authorityAd: `https://login.microsoftonline.com/${azureAdTenant}/v2.0`,
            redirectUri: 'https://amazonia-dev.onetree.com',

        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage
        },
    }
}